@import "../../assets/themes/user-variables";

.valuesCard{
  border-color: rgba($black, 0.05);
  border-radius: .25rem;
}
.valuesInput{
  background: transparent;
  border: .0625rem solid transparent;
  height: 1.75rem;
  &:not([readonly]):focus, &:not([readonly]):focus-visible{
    background: transparent;
    border:.0625rem solid transparent;
    box-shadow: none;
    outline: none;
  }
}