@import "../../assets/themes/user-variables";

.sidebar {
  position: fixed;
  left: 0;
  height: 100%;
  top: 0;
  padding:6.25rem 1.25rem 1.25rem 0;
  z-index: 1029;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 270px;
}

.menu{
  padding: 0;
}

.sidebarMenu{
  li{
    margin-bottom: .25rem;
  }
}

.menuLink{
  color: $black;
  text-decoration: none;
  padding: .5rem .5rem .5rem 2.5rem;
  display: block;
  border-radius: 0.25rem;
  &:hover{
    background-color: rgba($red-11, .05);
    color: $black;
  }
  &:global(.active){
    background-color: rgba($red-11, .05);
    color: $black;
  }
}

.hasLeftIcon{
  padding-left: 3.5rem;
  position: relative;
  .leftIcon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2rem;
  }
}

.caretIcon{
  cursor: pointer;
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  z-index: 5;
  padding: 0.75rem 0.5rem;
}

.menuBtn{
  text-align: left;
  &:hover, &:focus, &:active:focus{
    border-color: rgba($red-11, .05);
  }
  @extend .menuLink;
}
.overflowYAuto{
  overflow-y:auto
}
.scrollBarHide{
  &::-webkit-scrollbar {
    width: 1px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  &::-webkit-scrollbar-thumb {
    background: transparent; 
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: transparent; 
  }
}
