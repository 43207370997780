// apply on file LogoConfiguration
.table {
  width: 100%;
}

.logoCol {
  width: 25%;
  color: rgba($color: #000000, $alpha: 0.7);
  text-align: center;
}

.fileNameCol {
  width: 35%;
  color: rgba($color: #000000, $alpha: 0.7);
}

.frontLogoCol {
  width: 20%;
}
.backLogoCol {
  width: 20%;
}

.logoImg {
  object-fit: contain;
  height: 100%;
  width: 100%;
  display: block;
}

.logoImgWrap {
  height: 60px;
  width: 60px;
  margin: 0 auto;
}