$black: #000;
$transparent: transparent;

$black-10:#606164;

$gray-10: #EAEAEA;
$gray-11: #F2F2F2;

$red-10: #DA3923;
$red-11: #C71E08;

$white: #fff;

$blue-10: #176AE0;
// font size
$font-size-xs: .75rem;
$font-size-sm: .875rem;
$font-size-base: 1rem;

$font-weight-semi-bold: 600;
$font-weight-extra-bold: 800;
$font-weight-medium: 500;
$font-weight-bold: 700;

//font family
$helvetica: 'Helveitca';
$montserrat: 'Montserrat';