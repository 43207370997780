

// Styles for order summary
.userEmail {
    font-size: 16px;
    color: #808080;
}
// Styles for purchase order
// Styles for Order Item
.sizeQtyInput {
    border: none;
    text-align: center;
    &:focus {
        outline:none;
    }
}
.itemColorGraphic {
    font-size: 14px;
    color: #808080;
}

// common
.inputLabel {
    font-weight: bold;
}

.sp_td_sr {
    width: 10%;
}

.sp_td {
    width: 45%;
}

.pr_td_sr {
    width: 10%;
}

.pr_td {
    width: 45%;
}