@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon/icomoon.eot?irqebl');
    src: url('../fonts/icomoon/icomoon.eot?irqebl#iefix') format('embedded-opentype'),
        url('../fonts/icomoon/icomoon.ttf?irqebl') format('truetype'),
        url('../fonts/icomoon/icomoon.woff?irqebl') format('woff'),
        url('../fonts/icomoon/icomoon.svg?irqebl#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-apparel:before {
    content: "\e902";
}
.icon-description:before {
    content: "\e903";
}
.icon-filter:before {
    content: "\e900";
}
.icon-label:before {
    content: "\e901";
}