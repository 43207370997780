.custom-dropdown{
  &.dropdown{
    padding: 0;
    height: auto;
    &.show{
      .no-caret-icon{
        .toggle-icon{
          &:before{
            content: "\f0d8";
          }
        }
      }
    }
  }

  .toggle-link{
    padding: 0;
    border: 0;
    background-color: transparent;
    color: $black;
    &:focus{
      box-shadow: none;
    }
  }
  .dropdown-menu{
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    border: none;
  }
  .toggle-left{
    text-align: left;
    position: relative;
    .toggle-icon{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: .75rem;
    }
  }
  .dropdown-item{
    &:focus{
      background-color: rgba($red-11, .05);
      color: $black;
    }
  }
}
.no-caret-icon{
  &.dropdown-toggle{
    &:after{
      display: none;
    }
  }
  .toggle-icon{
    &:before{
      content: "\f0d7";
    }
  }
}

.menu-scroll{
  max-height: 20rem;
  overflow-y: auto;
}