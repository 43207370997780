@import "../../assets/themes/user-variables";

.templatesListItem {
  padding: .25rem;
  margin-bottom: .125rem;
  position: relative;

  :global(.has-right-action) {
    min-height: 70px;
    padding-left: 114px;
  }

  :global(.avatar) {
    position: absolute;
    left: 36px;
    top: 0;
  }

  :global(.action-wrap) {
    display: none;
  }

  &:hover {
    background-color: rgba($red-11, .05);

    :global(.action-wrap) {
      display: block;
    }
  }
}

.thumbnailWrap {
  position: relative;
  padding-left: 2.125rem;
  min-height: 1.5rem;

  :global(.avatar) {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.image_container {
  position: relative;
  // margin-top: 10px;
  height: 100%;
  width: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.front_img {
  width: calc(100% - 1.5rem);
  height: calc(100% - 1.5rem);
  position: absolute;
  bottom: 0;
  left: 0;
}

.noBackImage {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.back_img {
  width: calc(100% - 1.5rem);
  height: calc(100% - 1.5rem);
  position: absolute;
  right: 0;
  top: 0;
}

.chipsWrap{
  display: 'flex';
  flex-wrap:'wrap';
  align-items: center;
  padding-left: 15px;
  margin: -6px -3px 0;
}
.chipsItem{
  display: inline-block;
  padding: 3px 6px;
  border-radius: 12px;
  font-size: 10px;
  line-height: 1;
  color: #c71e08; 
  border: 1px solid #c71e08;
  margin: 6px 3px 0; 
}

.chipsItemRest{
  display: inline-block;
  padding: 3px 6px;
  font-size: 10px;
  line-height: 1;
  color: #c71e08; 
  margin: 6px 3px 0; 
}