@import '../../assets/themes/user-variables';

.loginLeftPanel{
  background-color: $gray-11;
  height: 100%;
  padding: 2.8125rem;
  position: relative;
  overflow: hidden;
}

.ellipseWrap{
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.loginCard{
  border-color: rgba($white,.5);
  background-color: rgba($white,.5);
  backdrop-filter: blur(14px);
  .cardBody{
    padding: 4.6875rem 3.5rem;
  }
}

.figureWrap{
  max-width: 26.125rem;
  margin: 0 auto;
}

.loginForm {
  max-width: 400px;
  width: 100%;
}

.formCard{
  box-shadow: 0 0 .25rem rgba($black, 0.1), 0 0.1875rem 0.1875rem rgba($black, 0.16);
  border-radius: 0.3125rem;
  padding: 1.875rem;
  border-color: $white;
}

@media (min-width: 768px){
  .loginWrap{
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
  .loginRow{
   height: 100%;
  }
  .logo{
    max-width:120px !important;
    height:auto ;
  }
}
