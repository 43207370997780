$path-to-fonts: '../../../../public/fonts' !default;

$headings-font-weight: 700;

//font family
$helvetica: 'Helveitca';
$font-family-base: $helvetica;
$primary: #176AE0;
$danger: #C71E08;
$dark: #000;
$border-color: rgba(0,0,0,0.12);
$light: #EAEAEA;