.graphics-rule-box {
    margin-bottom: 24px;
    .disabled-rule{
        opacity: 0.1 !important;
        background-color: #fff !important;
        label{
            color: #000 !important;
        }
    }
    .graphics-scroll {
        overflow: hidden;
        overflow-x: auto;
        width: calc(100% - 16px);
    }

    .form-check-wrap {
        display: flex;
        align-items: center;

        input {
            position: relative;
            opacity: 1;
            width: 20px;
            height: 20px;

            &:checked {
                &::after {
                    content: "";
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    width: 11px;
                    height: 6px;
                    border-left: 2px solid #000000;
                    border-bottom: 2px solid #000000;
                    transform: rotate(-45deg);
                }
            }

            &::before {
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                background-color: #fff;
                border: 1px solid #000000;
            }
        }

        label {
            text-align: left;
            padding-left: 20px;
            font-size: 14px;
            line-height: 18px;
            opacity: 1;
        }
    }

    h3 {
        margin-bottom: 8px;
    }

    table {
        th {
            background-color: rgba(199, 30, 8, 0.05);
        }

        td,
        th {
            padding: 8px 10px;
            text-align: center;
            border: 1px solid #000000;
            background-color: #fff;
            min-width: 140px;
        }

        thead {
            th {
                .form-check-wrap {
                    label {

                        font-size: 16px;
                        line-height: 20px;
                    }
                }

            }
        }

        tbody {
            th {
                text-align: left;

                .form-check-wrap {
                    flex-direction: row-reverse;

                    label {
                        text-align: right;
                        padding-right: 10px;
                        padding-left: 0;
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }

            input {
                width: 20px;
                height: 20px;
                font-size: 16px;

                &:checked {
                    background-color: rgba(199, 30, 8, 0.8);
                }
            }
        }
    }

}