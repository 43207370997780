.template-accordion {
  &.accordion {
    .accordion-header {
      .template-header-left {
        padding-right: 24px;
        position: relative;
        .fa-chevron-down {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%) rotate(-90deg);
        }
      }
    }
    .accordion-body {
      padding-left: 80px;
      padding-right: 4px;
      .fa-chevron-down {
        display: none;
      }
    }
    .accordion-item {
      border: none;
      border-bottom: 1px solid $gray-10;
    }
    .accordion-button {
      color: $black;
      padding-left: 4px;
      padding-right: 4px;
      &:focus {
        box-shadow: none;
      }
      &::after {
        display: none;
      }
      &:not(.collapsed) {
        background-color: $white;
        .fa-chevron-down {
          transform: translateY(-50%) rotate(0);
        }
      }
    }
    .template-header-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      img {
        width: 64px;
        height: 64px;
        border: 1px solid $gray-10;
        border-radius: 8px;
        margin-right: 12px;
      }
      .badge {
        background-color: $gray-10;
        color: $black-10;
        line-height: 1;
        font-size: 12px;
        height: 22px;
        border-radius: 30px;
        margin-left: 4px;
      }
    }
    .template-header-left {
      display: flex;
      align-items: center;
    }
    .template-header-img {
      display: flex;
      align-items: center;
      p {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        font-size: 14px;
      }
    }
  }
}

.default-accordion {
  &.accordion {
    padding: 0 12px;
    .accordion-item {
      border: none;
    }
    .accordion-button {
      padding: 4px;
      position: relative;
      color: $black;
      box-shadow: none;
      &:focus {
        box-shadow: none;
      }
      &:not(.collapsed) {
        background-color: $white;
        &::before {
          content: "\f0d7";
        }
      }
      &::after {
        display: none;
      }
      &::before {
        position: absolute;
        left: -10px;
        bottom: 4px;
        content: "\f0da";
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        color: $danger;
      }
    }
    .accordion-body {
      border: 1px solid $black;
      border-radius: 8px;
      padding: 8px;
    }
  }
}

.default-graphics-table {
  display: flex;
  align-items: flex-start;

  .default-graphics-img {
    width: 200px;
    padding-right: 24px;
    padding-top: 20px;
    .item-img {
      width: 100%;
      height: 120px;
      object-fit: contain;
      border: 1px solid $gray-10;
      padding: 4px;
    }
  }
  table {
    width: calc(100% - 200px);
    th,
    td {
      border-bottom: 1px solid $gray-10;
      text-align: center;
    }
  }
  .btn-group {
    .form-control {
      text-align: center;
      max-width: 40px;
      height: 22px;
      min-height: 22px;
      border: 1px solid black;
      background: white;
      border-radius: 0;
      border-left: none;
      border-right: none;
    }
    .btn {
      border: 1px solid black;
      background: white;
      padding: 0;
      width: 22px;
      height: 22px;
    }
  }
}
