.searchBar {
  position: relative;
  :global(.form-control){
    padding-left: 3rem;
  }
  .iconLeft{
    position: absolute;
    left: .625rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
