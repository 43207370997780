.label-edit-modal {
  &.modal {
    .modal-dialog {
      max-width: 400px;
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      max-height: 400px;
      overflow: hidden;
      overflow-y: auto;

      li {
        padding: 8px 0;

        .form-control {
          pointer-events: none;
          background-color: $white;
          border-color: $white;

          &.editable-input {
            background: rgba($black, 0.05);
            border-color: rgba($black, 0.05);
            pointer-events: initial;
          }
        }
      }
    }

    .btn-controls {
      display: flex;
      justify-content: flex-end;
      min-width: 50px;
    }
  }
}

.link-modal-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .link-modal-left {
    width: calc(100% - 130px);

    input {
      width: 100%;
      padding: 6px 10px;
      border-radius: 5px 0 0 5px;
      border-right: none;
    }
  }

  .link-modal-right {
    width: 130px;

    .btn {
      width: 100%;
      border-radius: 0 5px 5px 0;
    }
  }
}

.nestedModalL1 {
  z-index: 1057;
}

.nestedBackdropL1 {
  z-index: 1056;
}

@media (min-width: 576px) {
  .custom-modal {
    .modal-sm {
      max-width: 24rem;
    }
  }
}