@font-face {
  font-family: $montserrat;
  src: url('#{$path-to-fonts}/montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $montserrat;
  src: url('#{$path-to-fonts}/montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $montserrat;
  src: url('#{$path-to-fonts}/montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $montserrat;
  src: url('#{$path-to-fonts}/montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $montserrat;
  src: url('#{$path-to-fonts}/montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: $montserrat;
  src: url('#{$path-to-fonts}/montserrat/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

//helvetica
@font-face {
  font-family: $helvetica;
  src: url('#{$path-to-fonts}/helvetica/Helvetica.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $montserrat;
  src: url('#{$path-to-fonts}/helvetica/Helvetica-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
