.input-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 15px;
  /* width: max-content; */
}

.input-field input {
  background-color: #dfdbdb;
  border-style: none;
  border-radius: 5px 5px 0 0;
  /* width: 652px; */
  width: 100%;
  height: 38px;
  padding: 0px 7px;
  margin: 8px 0;
  font-weight: 400;
  font-size: 20;
}

.input-field input:focus-visible {
  outline-style: none;
}
