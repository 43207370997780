.form-control {
  background: rgba($black, 0.05);
  border-color: rgba($black, 0.05);
  border-radius: .25rem;

  &:not([readonly]):focus {
    background: rgba($black, 0.05);
    border-color: rgba($black, 0.05);
    box-shadow: none;

    &.is-invalid {
      border-color: $danger;
    }
  }
}

//custom checkbox and radio
%custom-control-input {
  z-index: 1;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 1.25rem;
  height: 1.25rem;
}

%custom-control-label-before {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  pointer-events: none;
  width: 1.25rem;
  height: 1.25rem;
  display: block;
  border: 0.125rem solid $black;
  background-color: $white;
}

.custom-checkbox {
  padding: 0;
  position: relative;

  &.grey-shade {

    .custom-control-input {
      + {
        .custom-control-label {
          &:before {
            border-color: $black-10
          }
        }
      }
    }

  }

  .custom-control-input {

    @extend %custom-control-input;

    +.custom-control-label {
      padding-left: 2rem;
      line-height: 1.25rem;

      &:before {
        @extend %custom-control-label-before;
        border-radius: 3px;
      }

      &:after {
        content: '';
        position: absolute;
        display: none;
        left: .5rem;
        top: .25rem;
        width: 0.3125rem;
        height: .625rem;
        border-style: solid;
        border-width: 0 0.125rem 0.125rem 0;
        border-color: $danger;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        border-radius: 0;
      }
    }

    &:not(:disabled) {
      &:active {
        ~.custom-control-label {
          &:before {
            border-color: $danger;
            background-color: $white;
          }
        }
      }
    }

    &.mulitple-input-mark {
      &:checked {
        ~.custom-control-label {
          &::after {
            width: 10px;
            background: red;
            height: 3px;
            transform: none;
            left: 5px;
            top: 8px;
          }
        }
      }
    }

    &:checked {
      ~.custom-control-label {
        color: $black;

        &:before {
          border-color: $danger;
          background-color: $white;
        }

        &:after {
          background-image: none;
          display: block;
        }
      }
    }

    &:focus {
      ~.custom-control-label {
        &:before {
          box-shadow: none;
        }
      }

      &:not(:checked) {
        ~.custom-control-label {
          &~.custom-control-label {
            &~.custom-control-label::before {
              border-color: $black;
            }
          }
        }
      }
    }

    &[disabled],
    &:disabled {
      ~.custom-control-label {
        color: rgba($black, 0.4);

        &:before {
          border-color: rgba($black, 0.4);
          background-color: $white;
        }
      }
    }
  }
}

.custom-radio {
  padding: 0;
  position: relative;

  .custom-control-input {
    @extend %custom-control-input;

    +.custom-control-label {
      padding-left: 2rem;
      line-height: 1.25rem;

      &:before {
        @extend %custom-control-label-before;
        border-radius: 50%;
      }

      &:after {
        content: '';
        position: absolute;
        background-color: $danger;
        display: none;
        left: .375rem;
        top: .375rem;
        width: .5rem;
        height: .5rem;
        border-radius: 50%;
      }
    }

    &:not(:disabled) {
      &:active {
        ~.custom-control-label {
          &:before {
            border-color: $danger;
            background-color: $white;
          }
        }
      }
    }

    &:checked {
      ~.custom-control-label {
        color: $black;

        &:before {
          border-color: $danger;
          background-color: $white;
        }

        &:after {
          background-image: none;
          display: block;
        }
      }
    }

    &:focus {
      ~.custom-control-label {
        &:before {
          box-shadow: none;
        }
      }

      &:not(:checked) {
        ~.custom-control-label {
          &~.custom-control-label {
            &~.custom-control-label::before {
              border-color: $black;
            }
          }
        }
      }
    }

    &[disabled],
    &:disabled {
      ~.custom-control-label {
        color: rgba($black, 0.4);

        &:before {
          border-color: rgba($black, 0.4);
          background-color: $white;
        }
      }
    }
  }
}

.hidden-input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
}