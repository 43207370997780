.image_container {
  position: relative;
  margin-top: 10px;
  height: 100%;
  width: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.front_img {
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  position: absolute;
  bottom: 0;
  left: 0;
}

.noBackImage {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.back_img {
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  position: absolute;
  right: 0;
  top: 0;
}