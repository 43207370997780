.avatar{
  overflow: hidden;
  width: 3rem;
  height: 3rem;
  img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
    &.object-fit-contain{
      object-fit: contain;
    }
  }
  &.avatar-circle{
    border-radius: 50%;
  }
  .btn-x{
    position: absolute;
    top: -.5rem;
    right: -.5rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: $gray-10;
    font-size: .875rem;
  }
  &.avatar-sm{
    width: 2rem;
    height: 2rem;
  }
  &.avatar-xs{
    width: 1.5rem;
    height: 1.5rem;
  }
  &.border-rounded{
    border: .0625rem solid rgba($black,.12);
    border-radius: 0.4375rem;
  }
  &.avatar-lg{
    width: 4rem;
    height: 4rem;
  }
}