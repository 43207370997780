.field {
  position: relative;
  display: inline-block;
}
.field__input {
  padding-right: 40px;
}
.field__helper {
  position: absolute;
  right: 5px;
  top: 11px;
  color: #999;
  font-size: 12px;
}
