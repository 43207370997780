@import "../../../assets/themes/user-variables";
.statusDropdown{
  min-width: 10rem;
}
.templateConfigList{
  li{
    border-bottom: .0625rem solid rgba($black, .12);
    padding: .5rem 0;
    &:first-child{
      padding-top: 0;
    }
    &:last-child{
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}


// apply on file LogoConfiguration
.table {
  width: 100%;
}

.logoCol {
  width: 25%;
  color: rgba($color: #000000, $alpha: 0.7);
  text-align: center;
}

.fileNameCol {
  width: 35%;
  color: rgba($color: #000000, $alpha: 0.7);
}

.frontLogoCol {
  width: 20%;
}
.backLogoCol {
  width: 20%;
}

.logoImg {
  object-fit: contain;
}

.logoImgWrap {
  height: 60px;
  width: 60px;
  margin: 0px auto;
}