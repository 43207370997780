@import "../../assets/themes/user-variables";
.fullPageLoader{
  position: fixed;
  z-index: 1200;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($black, .12);
}