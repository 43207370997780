.dropdown {
  display: block;
  padding: 0 10px;
  height: 38px;
  width: auto;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  -webkit-appearance: none;
}
