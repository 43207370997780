.list-style-none {
  list-style: none;
}

.list-item-mb-2 {
  margin-bottom: .5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.btn-list {
  li {
    padding: 0 .5rem;
    border-right: .0625rem solid $border-color;

    &:last-child {
      padding-right: 0;
      border-right: 0;
    }
  }

}

.list-group {
  &.has-border-bottom {
    .list-item-mb-2 {
      padding: 4px 0;
      border-bottom: 1px solid rgba($black-10, 0.4);

      &.no-border {
        border-bottom: none;
      }
    }

  }
}

.label-dropdown {
  position: relative;

  &.active {
    .dropdown-menu {
      display: block;
    }
  }

  .dropdown-menu {
    width: 250px;
    position: absolute;
    top: 100%;
    right: 0;
    padding: 0;

    input {
      border: 1px solid rgba($black-10, 0.4);
      background-color: $white;
    }
  }
}