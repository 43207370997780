.custom-size-input-wrap{
  display: flex;
  .input-wrap{
    margin-right: 4px;
    background-color: rgba($black, 0.05);
    border: 1px solid $transparent;
    border-radius: 0.25rem;
    padding: 6px 2px;
    &:focus-within{
      border-color: $red-11;
      .form-control{
        border-bottom-color: $transparent;
      }
    }
    &:nth-last-child(1){
      margin-right: 0;
    }
    label{
      width: 100%;
      text-align: center;
    }
    .form-control{
      background: none;
      border: none;
      text-align: center;
      padding: 0;
      border-bottom: 1px solid black;
      border-radius: 0;
    }
  }
}