.btn-link{
    padding: 0;
    border: 0;
    text-decoration: none;
    font-weight: $font-weight-normal;
    &:focus,&:active, &:active:focus{
        box-shadow: none;
    }
}
.btn-link-danger{
    @extend  .btn-link;
    color: $danger;
    &:focus, &:hover, &:active, &:active:focus{
        color: $danger;
        box-shadow: none;
    }
}
.btn-upload{
    height: 160px;
    border-color: rgba($black,.12);
    width: 100%;
    display: block;
    color: rgba($black,.6);
}
.btn-white{
    @include button-variant($white, $border-color, $hover-background: darken($white, 7.5%), $hover-border: darken($border-color, 10%), $active-background: darken($white, 10%), $active-border: darken($border-color, 12.5%));
    &:focus,
    &:active:focus {
        box-shadow: none;
    }
    &.disabled,
    &:disabled {
        color: $white  !important;
        background-color: rgba($black, .15) !important;
        border-color: rgba($black, .15) !important;
        opacity: 1;
    }
}

.btn-img{
    img{
        width: 1.5rem;
    }
}