.custom-tabs{
  .nav-tabs{
    border-bottom-color: rgba($black, .12);
    .nav-link{
      font-weight: $font-weight-normal;
      color: $black;
      padding: 0 0 0.5rem;
      border: 0;
      background-color: transparent;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: .1875rem;
        border-radius: .125rem;
        pointer-events: none;
      }
      &.active{
        &:after{
          background-color: $red-11;
        }
        color: $black;
      }
    }
  }
  .nav-item{
    width: 170px;
    margin-right: 1.5rem;
  }
  .tab-content{
    padding: .875rem 0;
  }
}
.custom-tabs1{
  .nav-tabs{
    border-bottom-color: rgba($black, .12);
    .nav-link{
      font-weight: $font-weight-normal;
      color: $black;
      padding: 0 0 0.5rem;
      border: 0;
      background-color: transparent;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: .1875rem;
        border-radius: .125rem;
        pointer-events: none;
      }
      &.active{
        &:after{
          background-color: $red-11;
        }
        color: $black;
      }
    }
  }
  .nav-item{
    margin-right: 1.5rem;
  }
  .tab-content{
    // padding: .875rem 0;
  }
}
.tab-top{
  // border-bottom: .0625rem solid rgba($black, .12);
}