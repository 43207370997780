@import "../../assets/themes/user-variables";

.header{
  border-bottom: .0625rem solid rgba($black,.12);
  padding: 1rem 0;
  background-color: $white;
  :global(.container-fluid){
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.navBar{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navBarBrand{
  width: 100px;
  img{
    max-height: 3rem;
  }
}
.menu{
  padding: 0;
  min-width: 15.625rem;
}
.profileInfo{
  position: relative;
  padding-left: 3.5rem;
  .profile{
    position: absolute;
    left: 0;
    top: 0;
  }
}
.email{
  color: rgba($black,.6);
  font-size: .75rem;
}
.changePassword{
  &:hover{
    background-color: transparent;
  }
}
.signOut{
  border-top: .0625rem solid rgba($black,.12);
  border-bottom: .0625rem solid rgba($black,.12);
}
.menuLink{
  font-size: $font-size-xs;
  color: rgba($black,.6);
  text-decoration: none;
}
.logo{
  max-width: 94px !important;
  height: auto;
}